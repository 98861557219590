import logo from './static/laconia-logo-white.png';

import './App.css';

function App() {
    return (
        <div className="App">
      <header className="App-header">
	    <video id="background-video" loop muted autoPlay poster="" class="background-video" id="background-video">
    <source src="https://s3.amazonaws.com/www.laconia.ai/static/memeghost_hero1.mp4" type="video/mp4" />
	    </video>
     <section class="hero">
        <div class="hero-inner">
            <h1>DJ MemeGhost</h1>
          <h2>In 20 years I'll be catfishin. No cap</h2>
<h3></h3>
<h3>Working on my new album, dropping on Spotify later this year</h3>
        </div>
    </section>
	    </header>
    </div>
  );
}

export default App;
